.p-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}

.p-grid > .p-col,
.p-grid > [class*=p-col] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.p-nogutter {
  margin-right: 0;
  margin-left: 0;
  margin-top: 0;
}

.p-nogutter > .p-col,
.p-nogutter > [class*=p-col-] {
  padding: 0;
}

.p-dir-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.p-dir-rev {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.p-dir-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.p-dir-col-rev {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.p-justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.p-justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.p-justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.p-justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.p-justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.p-justify-even {
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
}

.p-align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.p-align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.p-align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.p-align-baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.p-align-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.p-col {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  padding: 0.5rem;
}

.p-col-fixed {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 0.5rem;
}

.p-col-align-start {
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.p-col-align-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.p-col-align-center {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.p-col-align-baseline {
  -ms-flex-item-align: baseline;
  align-self: baseline;
}

.p-col-align-stretch {
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.p-dir-col > .p-col,
.p-dir-col-rev > .p-col {
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.p-col-1,
.p-col-2,
.p-col-3,
.p-col-4,
.p-col-5,
.p-col-6,
.p-col-7,
.p-col-8,
.p-col-9,
.p-col-10,
.p-col-11,
.p-col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding: 0.5rem;
}

.p-col-1 {
  width: 8.3333%;
}

.p-col-2 {
  width: 16.6667%;
}

.p-col-3 {
  width: 25%;
}

.p-col-4 {
  width: 33.3333%;
}

.p-col-5 {
  width: 41.6667%;
}

.p-col-6 {
  width: 50%;
}

.p-col-7 {
  width: 58.3333%;
}

.p-col-8 {
  width: 66.6667%;
}

.p-col-9 {
  width: 75%;
}

.p-col-10 {
  width: 83.3333%;
}

.p-col-11 {
  width: 91.6667%;
}

.p-col-12 {
  width: 100%;
}

.p-col-order-first {
  -ms-flex-order: -1;
  order: -1;
}

.p-col-order-last {
  -ms-flex-order: 13;
  order: 13;
}

.p-col-order-0 {
  -ms-flex-order: 0;
  order: 0;
}

.p-col-order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.p-col-order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.p-col-order-3 {
  -ms-flex-order: 3;
  order: 3;
}

.p-col-order-4 {
  -ms-flex-order: 4;
  order: 4;
}

.p-col-order-5 {
  -ms-flex-order: 5;
  order: 5;
}

.p-col-order-6 {
  -ms-flex-order: 6;
  order: 6;
}

.p-col-order-7 {
  -ms-flex-order: 7;
  order: 7;
}

.p-col-order-8 {
  -ms-flex-order: 8;
  order: 8;
}

.p-col-order-9 {
  -ms-flex-order: 9;
  order: 9;
}

.p-col-order-10 {
  -ms-flex-order: 10;
  order: 10;
}

.p-col-order-11 {
  -ms-flex-order: 11;
  order: 11;
}

.p-col-order-12 {
  -ms-flex-order: 12;
  order: 12;
}

.p-offset-12 {
  margin-left: 100%;
}

.p-offset-11 {
  margin-left: 91.66666667%;
}

.p-offset-10 {
  margin-left: 83.33333333%;
}

.p-offset-9 {
  margin-left: 75%;
}

.p-offset-8 {
  margin-left: 66.66666667%;
}

.p-offset-7 {
  margin-left: 58.33333333%;
}

.p-offset-6 {
  margin-left: 50%;
}

.p-offset-5 {
  margin-left: 41.66666667%;
}

.p-offset-4 {
  margin-left: 33.33333333%;
}

.p-offset-3 {
  margin-left: 25%;
}

.p-offset-2 {
  margin-left: 16.66666667%;
}

.p-offset-1 {
  margin-left: 8.33333333%;
}

.p-offset-0 {
  margin-left: 0%;
}

.p-sm-1,
.p-sm-2,
.p-sm-3,
.p-sm-4,
.p-sm-5,
.p-sm-6,
.p-sm-7,
.p-sm-8,
.p-sm-9,
.p-sm-10,
.p-sm-11,
.p-sm-12,
.p-md-1,
.p-md-2,
.p-md-3,
.p-md-4,
.p-md-5,
.p-md-6,
.p-md-7,
.p-md-8,
.p-md-9,
.p-md-10,
.p-md-11,
.p-md-12,
.p-lg-1,
.p-lg-2,
.p-lg-3,
.p-lg-4,
.p-lg-5,
.p-lg-6,
.p-lg-7,
.p-lg-8,
.p-lg-9,
.p-lg-10,
.p-lg-11,
.p-lg-12,
.p-xl-1,
.p-xl-2,
.p-xl-3,
.p-xl-4,
.p-xl-5,
.p-xl-6,
.p-xl-7,
.p-xl-8,
.p-xl-9,
.p-xl-10,
.p-xl-11,
.p-xl-12 {
  padding: 0.5rem;
}

.p-col-nogutter {
  padding: 0;
}

@media screen and (min-width: 576px) {
  .p-sm-1,
.p-sm-2,
.p-sm-3,
.p-sm-4,
.p-sm-5,
.p-sm-6,
.p-sm-7,
.p-sm-8,
.p-sm-9,
.p-sm-10,
.p-sm-11,
.p-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .p-sm-1 {
    width: 8.3333%;
  }

  .p-sm-2 {
    width: 16.6667%;
  }

  .p-sm-3 {
    width: 25%;
  }

  .p-sm-4 {
    width: 33.3333%;
  }

  .p-sm-5 {
    width: 41.6667%;
  }

  .p-sm-6 {
    width: 50%;
  }

  .p-sm-7 {
    width: 58.3333%;
  }

  .p-sm-8 {
    width: 66.6667%;
  }

  .p-sm-9 {
    width: 75%;
  }

  .p-sm-10 {
    width: 83.3333%;
  }

  .p-sm-11 {
    width: 91.6667%;
  }

  .p-sm-12 {
    width: 100%;
  }

  .p-sm-offset-12 {
    margin-left: 100%;
  }

  .p-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .p-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .p-sm-offset-9 {
    margin-left: 75%;
  }

  .p-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .p-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .p-sm-offset-6 {
    margin-left: 50%;
  }

  .p-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .p-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .p-sm-offset-3 {
    margin-left: 25%;
  }

  .p-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .p-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .p-sm-offset-0 {
    margin-left: 0%;
  }

  .p-sm-order-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .p-sm-order-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .p-sm-order-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .p-sm-order-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .p-sm-order-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .p-sm-order-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .p-sm-order-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .p-sm-order-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .p-sm-order-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .p-sm-order-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .p-sm-order-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .p-sm-order-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .p-sm-order-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .p-sm-order-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .p-sm-order-12 {
    -ms-flex-order: 12;
    order: 12;
  }
}
@media screen and (min-width: 768px) {
  .p-md-1,
.p-md-2,
.p-md-3,
.p-md-4,
.p-md-5,
.p-md-6,
.p-md-7,
.p-md-8,
.p-md-9,
.p-md-10,
.p-md-11,
.p-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .p-md-1 {
    width: 8.3333%;
  }

  .p-md-2 {
    width: 16.6667%;
  }

  .p-md-3 {
    width: 25%;
  }

  .p-md-4 {
    width: 33.3333%;
  }

  .p-md-5 {
    width: 41.6667%;
  }

  .p-md-6 {
    width: 50%;
  }

  .p-md-7 {
    width: 58.3333%;
  }

  .p-md-8 {
    width: 66.6667%;
  }

  .p-md-9 {
    width: 75%;
  }

  .p-md-10 {
    width: 83.3333%;
  }

  .p-md-11 {
    width: 91.6667%;
  }

  .p-md-12 {
    width: 100%;
  }

  .p-md-offset-12 {
    margin-left: 100%;
  }

  .p-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .p-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .p-md-offset-9 {
    margin-left: 75%;
  }

  .p-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .p-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .p-md-offset-6 {
    margin-left: 50%;
  }

  .p-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .p-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .p-md-offset-3 {
    margin-left: 25%;
  }

  .p-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .p-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .p-md-offset-0 {
    margin-left: 0%;
  }

  .p-md-order-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .p-md-order-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .p-md-order-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .p-md-order-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .p-md-order-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .p-md-order-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .p-md-order-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .p-md-order-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .p-md-order-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .p-md-order-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .p-md-order-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .p-md-order-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .p-md-order-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .p-md-order-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .p-md-order-12 {
    -ms-flex-order: 12;
    order: 12;
  }
}
@media screen and (min-width: 992px) {
  .p-lg-1,
.p-lg-2,
.p-lg-3,
.p-lg-4,
.p-lg-5,
.p-lg-6,
.p-lg-7,
.p-lg-8,
.p-lg-9,
.p-lg-10,
.p-lg-11,
.p-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .p-lg-1 {
    width: 8.3333%;
  }

  .p-lg-2 {
    width: 16.6667%;
  }

  .p-lg-3 {
    width: 25%;
  }

  .p-lg-4 {
    width: 33.3333%;
  }

  .p-lg-5 {
    width: 41.6667%;
  }

  .p-lg-6 {
    width: 50%;
  }

  .p-lg-7 {
    width: 58.3333%;
  }

  .p-lg-8 {
    width: 66.6667%;
  }

  .p-lg-9 {
    width: 75%;
  }

  .p-lg-10 {
    width: 83.3333%;
  }

  .p-lg-11 {
    width: 91.6667%;
  }

  .p-lg-12 {
    width: 100%;
  }

  .p-lg-offset-12 {
    margin-left: 100%;
  }

  .p-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .p-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .p-lg-offset-9 {
    margin-left: 75%;
  }

  .p-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .p-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .p-lg-offset-6 {
    margin-left: 50%;
  }

  .p-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .p-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .p-lg-offset-3 {
    margin-left: 25%;
  }

  .p-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .p-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .p-lg-offset-0 {
    margin-left: 0%;
  }

  .p-lg-order-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .p-lg-order-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .p-lg-order-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .p-lg-order-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .p-lg-order-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .p-lg-order-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .p-lg-order-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .p-lg-order-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .p-lg-order-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .p-lg-order-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .p-lg-order-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .p-lg-order-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .p-lg-order-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .p-lg-order-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .p-lg-order-12 {
    -ms-flex-order: 12;
    order: 12;
  }
}
@media screen and (min-width: 1200px) {
  .p-xl-1,
.p-xl-2,
.p-xl-3,
.p-xl-4,
.p-xl-5,
.p-xl-6,
.p-xl-7,
.p-xl-8,
.p-xl-9,
.p-xl-10,
.p-xl-11,
.p-xl-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .p-xl-1 {
    width: 8.3333%;
  }

  .p-xl-2 {
    width: 16.6667%;
  }

  .p-xl-3 {
    width: 25%;
  }

  .p-xl-4 {
    width: 33.3333%;
  }

  .p-xl-5 {
    width: 41.6667%;
  }

  .p-xl-6 {
    width: 50%;
  }

  .p-xl-7 {
    width: 58.3333%;
  }

  .p-xl-8 {
    width: 66.6667%;
  }

  .p-xl-9 {
    width: 75%;
  }

  .p-xl-10 {
    width: 83.3333%;
  }

  .p-xl-11 {
    width: 91.6667%;
  }

  .p-xl-12 {
    width: 100%;
  }

  .p-xl-offset-12 {
    margin-left: 100%;
  }

  .p-xl-offset-11 {
    margin-left: 91.66666667%;
  }

  .p-xl-offset-10 {
    margin-left: 83.33333333%;
  }

  .p-xl-offset-9 {
    margin-left: 75%;
  }

  .p-xl-offset-8 {
    margin-left: 66.66666667%;
  }

  .p-xl-offset-7 {
    margin-left: 58.33333333%;
  }

  .p-xl-offset-6 {
    margin-left: 50%;
  }

  .p-xl-offset-5 {
    margin-left: 41.66666667%;
  }

  .p-xl-offset-4 {
    margin-left: 33.33333333%;
  }

  .p-xl-offset-3 {
    margin-left: 25%;
  }

  .p-xl-offset-2 {
    margin-left: 16.66666667%;
  }

  .p-xl-offset-1 {
    margin-left: 8.33333333%;
  }

  .p-xl-offset-0 {
    margin-left: 0%;
  }

  .p-xl-order-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .p-xl-order-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .p-xl-order-0 {
    -ms-flex-order: 0;
    order: 0;
  }

  .p-xl-order-1 {
    -ms-flex-order: 1;
    order: 1;
  }

  .p-xl-order-2 {
    -ms-flex-order: 2;
    order: 2;
  }

  .p-xl-order-3 {
    -ms-flex-order: 3;
    order: 3;
  }

  .p-xl-order-4 {
    -ms-flex-order: 4;
    order: 4;
  }

  .p-xl-order-5 {
    -ms-flex-order: 5;
    order: 5;
  }

  .p-xl-order-6 {
    -ms-flex-order: 6;
    order: 6;
  }

  .p-xl-order-7 {
    -ms-flex-order: 7;
    order: 7;
  }

  .p-xl-order-8 {
    -ms-flex-order: 8;
    order: 8;
  }

  .p-xl-order-9 {
    -ms-flex-order: 9;
    order: 9;
  }

  .p-xl-order-10 {
    -ms-flex-order: 10;
    order: 10;
  }

  .p-xl-order-11 {
    -ms-flex-order: 11;
    order: 11;
  }

  .p-xl-order-12 {
    -ms-flex-order: 12;
    order: 12;
  }
}
.p-field {
  margin-bottom: 1rem;
}

.p-field > label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.p-field.p-grid > label {
  display: flex;
  align-items: center;
}

.p-field > small {
  margin-top: 0.25rem;
}

.p-field.p-grid,
.p-formgrid.p-grid {
  margin-top: 0;
}

.p-field.p-grid .p-col-fixed,
.p-formgrid.p-grid .p-col-fixed,
.p-field.p-grid .p-col,
.p-formgrid.p-grid .p-col,
.p-formgroup .p-grid .p-col-1,
.p-formgrid.p-grid .p-col-1,
.p-field.p-grid .p-col-2,
.p-formgrid.p-grid .p-col-2,
.p-field.p-grid .p-col-3,
.p-formgrid.p-grid .p-col-3,
.p-field.p-grid .p-col-4,
.p-formgroup.p-grid .p-col-4,
.p-field.p-grid .p-col-5,
.p-formgrid.p-grid .p-col-5,
.p-field.p-grid .p-col-6,
.p-formgrid.p-grid .p-col-6,
.p-formgroup .p-grid .p-col-7,
.p-formgrid.p-grid .p-col-7,
.p-field.p-grid .p-col-8,
.p-formgrid.p-grid .p-col-8,
.p-formgroup .p-grid .p-col-9,
.p-formgrid.p-grid .p-col-9,
.p-field.p-grid .p-col-10,
.p-formgrid.p-grid .p-col-10,
.p-field.p-grid .p-col-11,
.p-formgroup.p-grid .p-col-11,
.p-field.p-grid .p-col-12,
.p-formgrid.p-grid .p-col-12 {
  padding-top: 0;
  padding-bottom: 0;
}

.p-formgroup-inline {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.p-formgroup-inline .p-field,
.p-formgroup-inline .p-field-checkbox,
.p-formgroup-inline .p-field-radiobutton {
  margin-right: 1rem;
}

.p-formgroup-inline .p-field > label,
.p-formgroup-inline .p-field-checkbox > label,
.p-formgroup-inline .p-field-radiobutton > label {
  margin-right: 0.5rem;
  margin-bottom: 0;
}

.p-field-checkbox,
.p-field-radiobutton {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.p-field-checkbox > label,
.p-field-radiobutton > label {
  margin-left: 0.5rem;
  line-height: 1;
}

.p-mt-0 {
  margin-top: 0 !important;
}

.p-mt-1 {
  margin-top: 0.25rem !important;
}

.p-mt-2 {
  margin-top: 0.5rem !important;
}

.p-mt-3 {
  margin-top: 1rem !important;
}

.p-mt-4 {
  margin-top: 1.5rem !important;
}

.p-mt-5 {
  margin-top: 2rem !important;
}

.p-mt-6 {
  margin-top: 3rem !important;
}

.p-mt-auto {
  margin-top: auto !important;
}

.p-mr-0 {
  margin-right: 0 !important;
}

.p-mr-1 {
  margin-right: 0.25rem !important;
}

.p-mr-2 {
  margin-right: 0.5rem !important;
}

.p-mr-3 {
  margin-right: 1rem !important;
}

.p-mr-4 {
  margin-right: 1.5rem !important;
}

.p-mr-5 {
  margin-right: 2rem !important;
}

.p-mr-6 {
  margin-right: 3rem !important;
}

.p-mr-auto {
  margin-right: auto !important;
}

.p-ml-0 {
  margin-left: 0 !important;
}

.p-ml-1 {
  margin-left: 0.25rem !important;
}

.p-ml-2 {
  margin-left: 0.5rem !important;
}

.p-ml-3 {
  margin-left: 1rem !important;
}

.p-ml-4 {
  margin-left: 1.5rem !important;
}

.p-ml-5 {
  margin-left: 2rem !important;
}

.p-ml-6 {
  margin-left: 3rem !important;
}

.p-ml-auto {
  margin-left: auto !important;
}

.p-mb-0 {
  margin-bottom: 0 !important;
}

.p-mb-1 {
  margin-bottom: 0.25rem !important;
}

.p-mb-2 {
  margin-bottom: 0.5rem !important;
}

.p-mb-3 {
  margin-bottom: 1rem !important;
}

.p-mb-4 {
  margin-bottom: 1.5rem !important;
}

.p-mb-5 {
  margin-bottom: 2rem !important;
}

.p-mb-6 {
  margin-bottom: 3rem !important;
}

.p-mb-auto {
  margin-bottom: auto !important;
}

.p-mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.p-mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.p-mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.p-mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.p-mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.p-mx-5 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.p-mx-6 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.p-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.p-my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.p-my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.p-my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.p-my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.p-my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.p-my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.p-my-6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.p-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.p-m-0 {
  margin: 0 !important;
}

.p-m-1 {
  margin: 0.25rem !important;
}

.p-m-2 {
  margin: 0.5rem !important;
}

.p-m-3 {
  margin: 1rem !important;
}

.p-m-4 {
  margin: 1.5rem !important;
}

.p-m-5 {
  margin: 2rem !important;
}

.p-m-6 {
  margin: 3rem !important;
}

.p-m-auto {
  margin: auto !important;
}

@media screen and (min-width: 576px) {
  .p-mt-sm-0 {
    margin-top: 0 !important;
  }

  .p-mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .p-mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .p-mt-sm-3 {
    margin-top: 1rem !important;
  }

  .p-mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .p-mt-sm-5 {
    margin-top: 2rem !important;
  }

  .p-mt-sm-6 {
    margin-top: 3rem !important;
  }

  .p-mt-sm-auto {
    margin-top: 3rem !important;
  }

  .p-mr-sm-0 {
    margin-right: 0 !important;
  }

  .p-mr-sm-1 {
    margin-right: 0.25rem !important;
  }

  .p-mr-sm-2 {
    margin-right: 0.5rem !important;
  }

  .p-mr-sm-3 {
    margin-right: 1rem !important;
  }

  .p-mr-sm-4 {
    margin-right: 1.5rem !important;
  }

  .p-mr-sm-5 {
    margin-right: 2rem !important;
  }

  .p-mr-sm-6 {
    margin-right: 3rem !important;
  }

  .p-mr-sm-auto {
    margin-right: auto !important;
  }

  .p-ml-sm-0 {
    margin-left: 0 !important;
  }

  .p-ml-sm-1 {
    margin-left: 0.25rem !important;
  }

  .p-ml-sm-2 {
    margin-left: 0.5rem !important;
  }

  .p-ml-sm-3 {
    margin-left: 1rem !important;
  }

  .p-ml-sm-4 {
    margin-left: 1.5rem !important;
  }

  .p-ml-sm-5 {
    margin-left: 2rem !important;
  }

  .p-ml-sm-6 {
    margin-left: 3rem !important;
  }

  .p-ml-sm-auto {
    margin-left: auto !important;
  }

  .p-mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .p-mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .p-mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .p-mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .p-mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .p-mb-sm-5 {
    margin-bottom: 2rem !important;
  }

  .p-mb-sm-6 {
    margin-bottom: 3rem !important;
  }

  .p-mb-sm-auto {
    margin-bottom: auto !important;
  }

  .p-mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .p-mx-sm-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .p-mx-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .p-mx-sm-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .p-mx-sm-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .p-mx-sm-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .p-mx-sm-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .p-mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .p-my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .p-my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .p-my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .p-my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .p-my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .p-my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .p-my-sm-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .p-my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .p-m-sm-0 {
    margin: 0 !important;
  }

  .p-m-sm-1 {
    margin: 0.25rem !important;
  }

  .p-m-sm-2 {
    margin: 0.5rem !important;
  }

  .p-m-sm-3 {
    margin: 1rem !important;
  }

  .p-m-sm-4 {
    margin: 1.5rem !important;
  }

  .p-m-sm-5 {
    margin: 2rem !important;
  }

  .p-m-sm-6 {
    margin: 3rem !important;
  }

  .p-m-sm-auto {
    margin: auto !important;
  }
}
@media screen and (min-width: 768px) {
  .p-mt-md-0 {
    margin-top: 0 !important;
  }

  .p-mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .p-mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .p-mt-md-3 {
    margin-top: 1rem !important;
  }

  .p-mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .p-mt-md-5 {
    margin-top: 2rem !important;
  }

  .p-mt-md-6 {
    margin-top: 3rem !important;
  }

  .p-mt-md-auto {
    margin-top: 3rem !important;
  }

  .p-mr-md-0 {
    margin-right: 0 !important;
  }

  .p-mr-md-1 {
    margin-right: 0.25rem !important;
  }

  .p-mr-md-2 {
    margin-right: 0.5rem !important;
  }

  .p-mr-md-3 {
    margin-right: 1rem !important;
  }

  .p-mr-md-4 {
    margin-right: 1.5rem !important;
  }

  .p-mr-md-5 {
    margin-right: 2rem !important;
  }

  .p-mr-md-6 {
    margin-right: 3rem !important;
  }

  .p-mr-md-auto {
    margin-right: auto !important;
  }

  .p-ml-md-0 {
    margin-left: 0 !important;
  }

  .p-ml-md-1 {
    margin-left: 0.25rem !important;
  }

  .p-ml-md-2 {
    margin-left: 0.5rem !important;
  }

  .p-ml-md-3 {
    margin-left: 1rem !important;
  }

  .p-ml-md-4 {
    margin-left: 1.5rem !important;
  }

  .p-ml-md-5 {
    margin-left: 2rem !important;
  }

  .p-ml-md-6 {
    margin-left: 3rem !important;
  }

  .p-ml-md-auto {
    margin-left: auto !important;
  }

  .p-mb-md-0 {
    margin-bottom: 0 !important;
  }

  .p-mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .p-mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .p-mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .p-mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .p-mb-md-5 {
    margin-bottom: 2rem !important;
  }

  .p-mb-md-6 {
    margin-bottom: 3rem !important;
  }

  .p-mb-md-auto {
    margin-bottom: auto !important;
  }

  .p-mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .p-mx-md-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .p-mx-md-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .p-mx-md-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .p-mx-md-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .p-mx-md-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .p-mx-md-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .p-mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .p-my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .p-my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .p-my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .p-my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .p-my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .p-my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .p-my-md-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .p-my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .p-m-md-0 {
    margin: 0 !important;
  }

  .p-m-md-1 {
    margin: 0.25rem !important;
  }

  .p-m-md-2 {
    margin: 0.5rem !important;
  }

  .p-m-md-3 {
    margin: 1rem !important;
  }

  .p-m-md-4 {
    margin: 1.5rem !important;
  }

  .p-m-md-5 {
    margin: 2rem !important;
  }

  .p-m-md-6 {
    margin: 3rem !important;
  }

  .p-m-md-auto {
    margin: auto !important;
  }
}
@media screen and (min-width: 992px) {
  .p-mt-lg-0 {
    margin-top: 0 !important;
  }

  .p-mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .p-mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .p-mt-lg-3 {
    margin-top: 1rem !important;
  }

  .p-mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .p-mt-lg-5 {
    margin-top: 2rem !important;
  }

  .p-mt-lg-6 {
    margin-top: 3rem !important;
  }

  .p-mt-lg-auto {
    margin-top: 3rem !important;
  }

  .p-mr-lg-0 {
    margin-right: 0 !important;
  }

  .p-mr-lg-1 {
    margin-right: 0.25rem !important;
  }

  .p-mr-lg-2 {
    margin-right: 0.5rem !important;
  }

  .p-mr-lg-3 {
    margin-right: 1rem !important;
  }

  .p-mr-lg-4 {
    margin-right: 1.5rem !important;
  }

  .p-mr-lg-5 {
    margin-right: 2rem !important;
  }

  .p-mr-lg-6 {
    margin-right: 3rem !important;
  }

  .p-mr-lg-auto {
    margin-right: auto !important;
  }

  .p-ml-lg-0 {
    margin-left: 0 !important;
  }

  .p-ml-lg-1 {
    margin-left: 0.25rem !important;
  }

  .p-ml-lg-2 {
    margin-left: 0.5rem !important;
  }

  .p-ml-lg-3 {
    margin-left: 1rem !important;
  }

  .p-ml-lg-4 {
    margin-left: 1.5rem !important;
  }

  .p-ml-lg-5 {
    margin-left: 2rem !important;
  }

  .p-ml-lg-6 {
    margin-left: 3rem !important;
  }

  .p-ml-lg-auto {
    margin-left: auto !important;
  }

  .p-mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .p-mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .p-mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .p-mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .p-mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .p-mb-lg-5 {
    margin-bottom: 2rem !important;
  }

  .p-mb-lg-6 {
    margin-bottom: 3rem !important;
  }

  .p-mb-lg-auto {
    margin-bottom: auto !important;
  }

  .p-mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .p-mx-lg-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .p-mx-lg-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .p-mx-lg-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .p-mx-lg-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .p-mx-lg-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .p-mx-lg-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .p-mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .p-my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .p-my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .p-my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .p-my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .p-my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .p-my-lg-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .p-my-lg-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .p-my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .p-m-lg-0 {
    margin: 0 !important;
  }

  .p-m-lg-1 {
    margin: 0.25rem !important;
  }

  .p-m-lg-2 {
    margin: 0.5rem !important;
  }

  .p-m-lg-3 {
    margin: 1rem !important;
  }

  .p-m-lg-4 {
    margin: 1.5rem !important;
  }

  .p-m-lg-5 {
    margin: 2rem !important;
  }

  .p-m-lg-6 {
    margin: 3rem !important;
  }

  .p-m-lg-auto {
    margin: auto !important;
  }
}
@media screen and (min-width: 1200px) {
  .p-mt-xl-0 {
    margin-top: 0 !important;
  }

  .p-mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .p-mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .p-mt-xl-3 {
    margin-top: 1rem !important;
  }

  .p-mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .p-mt-xl-5 {
    margin-top: 2rem !important;
  }

  .p-mt-xl-6 {
    margin-top: 3rem !important;
  }

  .p-mt-xl-auto {
    margin-top: 3rem !important;
  }

  .p-mr-xl-0 {
    margin-right: 0 !important;
  }

  .p-mr-xl-1 {
    margin-right: 0.25rem !important;
  }

  .p-mr-xl-2 {
    margin-right: 0.5rem !important;
  }

  .p-mr-xl-3 {
    margin-right: 1rem !important;
  }

  .p-mr-xl-4 {
    margin-right: 1.5rem !important;
  }

  .p-mr-xl-5 {
    margin-right: 2rem !important;
  }

  .p-mr-xl-6 {
    margin-right: 3rem !important;
  }

  .p-mr-xl-auto {
    margin-right: auto !important;
  }

  .p-ml-xl-0 {
    margin-left: 0 !important;
  }

  .p-ml-xl-1 {
    margin-left: 0.25rem !important;
  }

  .p-ml-xl-2 {
    margin-left: 0.5rem !important;
  }

  .p-ml-xl-3 {
    margin-left: 1rem !important;
  }

  .p-ml-xl-4 {
    margin-left: 1.5rem !important;
  }

  .p-ml-xl-5 {
    margin-left: 2rem !important;
  }

  .p-ml-xl-6 {
    margin-left: 3rem !important;
  }

  .p-ml-xl-auto {
    margin-left: auto !important;
  }

  .p-mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .p-mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .p-mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .p-mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .p-mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .p-mb-xl-5 {
    margin-bottom: 2rem !important;
  }

  .p-mb-xl-6 {
    margin-bottom: 3rem !important;
  }

  .p-mb-xl-auto {
    margin-bottom: auto !important;
  }

  .p-mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .p-mx-xl-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .p-mx-xl-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .p-mx-xl-3 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .p-mx-xl-4 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .p-mx-xl-5 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .p-mx-xl-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .p-mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .p-my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .p-my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .p-my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .p-my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .p-my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .p-my-xl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .p-my-xl-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .p-my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .p-m-xl-0 {
    margin: 0 !important;
  }

  .p-m-xl-1 {
    margin: 0.25rem !important;
  }

  .p-m-xl-2 {
    margin: 0.5rem !important;
  }

  .p-m-xl-3 {
    margin: 1rem !important;
  }

  .p-m-xl-4 {
    margin: 1.5rem !important;
  }

  .p-m-xl-5 {
    margin: 2rem !important;
  }

  .p-m-xl-6 {
    margin: 3rem !important;
  }

  .p-m-xl-auto {
    margin: auto !important;
  }
}
.p-shadow-1 {
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.p-shadow-2 {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-shadow-3 {
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.p-shadow-4 {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.p-shadow-5 {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12);
}

.p-shadow-6 {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

.p-shadow-7 {
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.p-shadow-8 {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.p-shadow-9 {
  box-shadow: 0 5px 6px -3px rgba(0, 0, 0, 0.2), 0 9px 12px 1px rgba(0, 0, 0, 0.14), 0 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.p-shadow-10 {
  box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2), 0 10px 14px 1px rgba(0, 0, 0, 0.14), 0 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.p-shadow-11 {
  box-shadow: 0 6px 7px -4px rgba(0, 0, 0, 0.2), 0 11px 15px 1px rgba(0, 0, 0, 0.14), 0 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.p-shadow-12 {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.p-shadow-13 {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.p-shadow-14 {
  box-shadow: 0 7px 9px -4px rgba(0, 0, 0, 0.2), 0 14px 21px 2px rgba(0, 0, 0, 0.14), 0 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.p-shadow-15 {
  box-shadow: 0 8px 9px -5px rgba(0, 0, 0, 0.2), 0 15px 22px 2px rgba(0, 0, 0, 0.14), 0 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.p-shadow-16 {
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.p-shadow-17 {
  box-shadow: 0 8px 11px -5px rgba(0, 0, 0, 0.2), 0 17px 26px 2px rgba(0, 0, 0, 0.14), 0 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.p-shadow-18 {
  box-shadow: 0 9px 11px -5px rgba(0, 0, 0, 0.2), 0 18px 28px 2px rgba(0, 0, 0, 0.14), 0 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.p-shadow-19 {
  box-shadow: 0 9px 12px -6px rgba(0, 0, 0, 0.2), 0 19px 29px 2px rgba(0, 0, 0, 0.14), 0 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.p-shadow-20 {
  box-shadow: 0 10px 13px -6px rgba(0, 0, 0, 0.2), 0 20px 31px 3px rgba(0, 0, 0, 0.14), 0 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.p-shadow-21 {
  box-shadow: 0 10px 13px -6px rgba(0, 0, 0, 0.2), 0 21px 33px 3px rgba(0, 0, 0, 0.14), 0 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.p-shadow-22 {
  box-shadow: 0 10px 14px -6px rgba(0, 0, 0, 0.2), 0 22px 35px 3px rgba(0, 0, 0, 0.14), 0 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.p-shadow-23 {
  box-shadow: 0 11px 14px -7px rgba(0, 0, 0, 0.2), 0 23px 36px 3px rgba(0, 0, 0, 0.14), 0 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.p-shadow-24 {
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
}

/*# sourceMappingURL=primeflex.css.map */
